export const allowlistAddresses = [
  ['0x9ae33b5a7b762599ece043e90f147add91792785', 1],
  ['0xf3d8a6d4c723df4f0bc12f55a8d91536dac88eeb', 1],
  ['0x3506b0041bde2d1836d21db318986600b543310f', 1],
  ['0x11ba624dd320b7be1496a18907c8a7ea2d8c4f51', 1],
  ['0xfabd8d52a6e88f3016f8453a79184c17ac606f9e', 2],
  ['0xaf0ad083a79c2bc0c9ba2cc3fd57e2cdf5e039e9', 4],
  ['0x46d2e852d2c346d1c722e0955e33da64cc56a731', 1],
  ['0x4b9053a21971185098d71976d28273fbb995a75b', 2],
  ['0xb14d6f655e2531356605547f315b61f4cd37b841', 1],
  ['0x0affbf300d863a6d169b1f3254e5aa42bbff60c4', 1],
  ['0xc481a51849a1d13fa3775c7e7428ae71b04c939a', 1],
  ['0x06e5b984b954eb584314fa32982ffbbae8855755', 1],
  ['0xd4146d9dd17862cfaa6c3fd32d8fd8f62e4d6356', 1],
  ['0xf165e74ec96f6303b30b3f4427d7691af62376f7', 1],
  ['0xf667f3021d83b21c393b8fa3bd5f202c2a973872', 1],
  ['0xe123780b6ea6355e18889a8ee270eef1c650fb0c', 3],
  ['0xd077bbdeffb4a3f39bf7a78ab2f50ecba9596b95', 3],
  ['0x81110cd03968d31c583a90d669a704cdf3ce39e6', 1],
  ['0xc0f8d269d87b46d207debe7c2dbff7ac95649a75', 2],
  ['0xec53045791ab02d6b108b05a15092d11fb6e6ce8', 1],
  ['0xbcaed754e1601dbaeaa8f1ac094bbbaa18edb0dc', 1],
  ['0x6a9a187885cc6e60f06c07f7b9542d2e772b23a9', 1],
  ['0x1c60fac1e803592cb364a1fca0a650ded87a29d3', 1],
  ['0x4ff543502485bdc638e696dee17d0bd78aa3b4d9', 2],
  ['0xc999e34971211f5d77b854d5aed4209c93f5a7db', 1],
  ['0x5fb04ddf12818355bd40e096995cb383e8f83e19', 1],
  ['0x5d7de1d7b6606f70cd2c7875e5d58264f08a0636', 1],
  ['0xadff048cca4007e1f3801e3d989f716968b7e59b', 1],
  ['0x0b32b9866b6e5957299a2d248a2d0975027567cf', 1],
  ['0xc0998160228d64827968cd16248dc322ad4ededb', 1],
  ['0xb4e94685130527864e4a1a435beca449113f7a1d', 1],
  ['0xe440964985d8005a5f44ed830fb3acfcef15dd04', 1],
  ['0xf762e07b270b3881467e28e4e0460977bedfe675', 14],
  ['0x7a971ed47670accf410c4ce6103baf378340ade7', 14],
  ['0x6a9a187885cc6e60f06c07f7b9542d2e772b23a9', 12],
  ['0x2694c4766e06d18710ffd7ff1a2a422f9af6173a', 12],
  ['0xfd55d2558fa1a8d6321336e681d4be2048af4117', 10],
  ['0x72055004578e5084f6f6581e652182a94865bc40', 8],
  ['0x3506b0041bde2d1836d21db318986600b543310f', 8],
  ['0xd64d7c9672b6784da7036a46351821973061d77b', 8],
  ['0x9ee83641e960758f57b4597e74467c934d8d81ef', 8],
  ['0x57ea25d951b2f6499708b3b75075e814f115bc99', 8],
  ['0xb4e94685130527864e4a1a435beca449113f7a1d', 8],
  ['0x4182fc1e3b6fc9a66944b84b74c0912eaa8049dd', 8],
  ['0xc0998160228d64827968cd16248dc322ad4ededb', 8],
  ['0x301c37744562a8150c288779c652f6234a5772ad', 8],
  ['0x5534f6f9d06ce2b2b34973834e8fa15f1545fac7', 8],
  ['0x215bc454da079d610abdf1619d1b29c7795a7996', 8],
  ['0xb6eade9593dd6a82e1394f85262fcd0dc1c2d613', 8],
  ['0xde73accf5b7293b23a09d595b0a53f6dc4f592a4', 8],
  ['0xec53045791ab02d6b108b05a15092d11fb6e6ce8', 6],
  ['0x038d90cb9daa4e5818b58fdeae7c677134e05e99', 6],
  ['0xa3412a78e600d535e539c484b30a019c4cf4487f', 6],
  ['0xa5ebf0e1ea18c48ada7c6f1e27cf8acc65b134bf', 6],
  ['0xa099ec99966fa615c29b1ec56ce403fe655bca40', 6],
  ['0xaafd63655e4da3b1f9c24e5d8bb6a660ff32c746', 6],
  ['0xbb8b0fdc1e35e78d14869fc4c5d79b16cc9fb6be', 6],
  ['0x252019c2b80334ee0d04854e1cf01ec15f949b62', 4],
  ['0xaa6e759013afa9b67c77a79fadbcae181d5bef38', 4],
  ['0x9f38d150384cc6cb4d95b40ac8b0d4207e164548', 4],
  ['0x5869804224edd9bce13ac31d76fbbfe0a021aba6', 4],
  ['0xaf076a02bd071905d768b502901ad8c94b752fcf', 4],
  ['0xa77f171c2884504a4d34e25354d651ea9e182d97', 4],
  ['0xe8815c5ddfc143c2b0433c5aa0f14d4c0c3f2848', 4],
  ['0x8657e9a5e0e131d998b29a4d1aff243b37ec8e5b', 4],
  ['0xe440964985d8005a5f44ed830fb3acfcef15dd04', 4],
  ['0x4ff543502485bdc638e696dee17d0bd78aa3b4d9', 4],
  ['0xe46105bbda606babb797b343b21f930668e83c1d', 4],
  ['0xf68ace9d21ec155306939e9007956ce6080ec15a', 4],
  ['0x2b39cb2b4dff21c762d8d1c8ec69034a888e4503', 4],
  ['0x5f52b7211b006d55cfced12308e9bee95df3321b', 4],
  ['0xb46e0c7bc15b3e734483cd32caae74b8920126fd', 4],
  ['0x706814339e0dea51d3253eb013ea2033be16ad69', 4],
  ['0x12b3c44612901927ee5ccf85ff3ce9710a8efd78', 4],
  ['0x413989ede597d8f9124903075e00594b686194d0', 4],
  ['0x48d8d6a965e3f70d78c80badf3932074f0cc9d01', 4],
  ['0x8ba9ac044a895dd6da264edb847199914af0d7e8', 4],
  ['0x15c28f98b5cd04b967e2e5bd441d9f1e614aa137', 4],
  ['0xfabd8d52a6e88f3016f8453a79184c17ac606f9e', 2],
  ['0xc481a51849a1d13fa3775c7e7428ae71b04c939a', 2],
  ['0xc1d8432beb3844fcbbdfd630121181dd35951d14', 2],
  ['0x77c0a351ddd34dcaa928bd4268f4d816edb5e749', 2],
  ['0x90c77f0ab0a9501c0775a2bd0e296a8c1ac8f9df', 2],
  ['0x11fcb66497628e2466df1e2ed2d5e5f7f4954693', 2],
  ['0x365d98625d5a91ce0e6b0f3a618a6f8a4650ef96', 2],
  ['0x709d155c89d5ea191555d83110dbd42e57b7b962', 2],
  ['0x7b05286c955a954647f7558c1c3ee35ef0c6bb3a', 2],
  ['0xd077bbdeffb4a3f39bf7a78ab2f50ecba9596b95', 2],
  ['0x0b32b9866b6e5957299a2d248a2d0975027567cf', 2],
  ['0x0350caf7c22bbd61838e07190c0ca4586de75151', 2],
  ['0x4c26c92f1d45750094dddab4585f35a03e07c4ab', 2],
  ['0x5d7de1d7b6606f70cd2c7875e5d58264f08a0636', 2],
  ['0x2efd1ce75672657ea387efe7d7f639bc6902c313', 2],
  ['0x59b5082ee437527f2364efc5bfc69b1196615521', 2],
  ['0x409534c1d9b3a189db71c9b7386c738557b8f426', 2],
  ['0xc74316fbf9be38c480efe54939d85902946020f0', 2],
  ['0x6bdc53740309acd1e6785093494bfb4bab862694', 2],
  ['0xb4eb800b80751b33e7ee99e7365997f4b6c04435', 2],
  ['0x24e060a94de09250f4a293616168f7f20d1f48e5', 2],
  ['0x39ee786712adb2cf14b5f78ea714152d2ffb865e', 2],
  ['0x61fd0d043d519f5a2bd05785000f30db96809429', 2],
  ['0xea106d5394d573baa798d54dd8550ba5d993950a', 2],
  ['0xc85ebaa1c11a0e65c2710784cc6bf91a3eb725b1', 2],
  ['0x9ef0e1516b42d9701712714e966f2249bca95cc9', 2],
  ['0xaa075c8ccb686ddd5ba521338f70d6d0a9e389b2', 2],
  ['0x86102c52d65ecc8b66213e99db8da2b978f68992', 2],
  ['0x4da124bb23e04362af31f21889f110a1f43c6dd0', 2],
  ['0xe40111b8a5a4d5c24e693ce01919f6e3d70756cc', 2],
  ['0x8b2ecb260c61ec9b92ab770b547053de56d8f55c', 2],
  ['0x6c4f65b0e552d7f9a9743908ce16117293e40623', 2],
  ['0x1fa6096f902220528b42963a84d171e4de67ac85', 2],
  ['0x24f037ffbaf3d40540d19a32f8b4e6fd09b04cd9', 2],
  ['0xb2e922fd3eb98f47d27a76d846e3a33992325096', 2],
  ['0x5b0f51bb2c345a771a34c935c0fa9564897b4250', 2],
  ['0x884a74e75ee36aeb99195f08454280889441c710', 2],
  ['0xc0f8d269d87b46d207debe7c2dbff7ac95649a75', 2],
  ['0xb5c7ffd157732d7ebd335334c23f096e6640a80a', 2],
  ['0xd9a0e26c7818e7ec5cb868f312eb21796586b6d0', 2],
  ['0x1c60fac1e803592cb364a1fca0a650ded87a29d3', 2],
  ['0x11e8d6a5a886232715cbdba92e427a875db8dcbc', 2],
  ['0xca911f2846dcc0975d0101141f4156db2c25a403', 2],
  ['0xf3d8a6d4c723df4f0bc12f55a8d91536dac88eeb', 2],
  ['0x44abe66c01eb559a742f7667d3db08fe9570cf08', 2],
  ['0x756a5df81a750e530d78dc6c9e677517c55aead9', 2],
  ['0x504fa0c1159fcf9d18700c7631a95ebd0edd6f71', 2],
  ['0x5fb04ddf12818355bd40e096995cb383e8f83e19', 2],
  ['0x1955f310531b001bef9876fa7b2026d588394d19', 2],
  ['0x3eb39336a9aae3be0b945ecbd904c18565b7a0f8', 2],
  ['0xadff048cca4007e1f3801e3d989f716968b7e59b', 2],
  ['0xdc57d360197da1217dfcd90252bbf6d06473fa99', 2],
  ['0xb14d6f655e2531356605547f315b61f4cd37b841', 2],
  ['0xbe8ef24203d7edfdf0fc6a6c751aedede2a4cc52', 2],
  ['0x0deff34ef53c3994f30ae7575e2d0f747419fb01', 2],
  ['0x4bce5438a4a42fa07532fb7245a655c81efdde83', 2],
  ['0x33e7561f5ca829dab180482a69bb5b7fa4e1198d', 2],
  ['0x19267b68a5cc4399296338b9006a86421fd53984', 2],
  ['0x1f67153ff9b581113e96d76892a7cd7d6568663d', 2],
  ['0x8c64d6336e4201d7bbeeb1848c2948c962ff919d', 2],
  ['0xd6982d78fac8081048c2308204ed3347fa0bc960', 2],
  ['0x212b9634aa7b917394453ea10d88bef403827430', 2],
  ['0x03e64fc0029a53f10c5ddbbff9e01350e0305e6c', 2],
  ['0x0eb6d54c9a1fc716a5bdc001eca30158e4e5d7b4', 2],
  ['0xfaed0b2937828ce9c39b14952f8083dad8518268', 2],
  ['0xb078a24ef489ec466d282e8681b84d1a15d06d17', 2],
  ['0x0a884fa3fa666225ff5be20d07f942133317c95d', 2],
  ['0xe9732b5005ebad386c70d681f34140880a656e71', 2],
  ['0x8817cab6adbd1b2f8b37b7989e7dc5e672b25a75', 2],
  ['0x6609ec70bf04f20ddd720470b2feda600427fc3a', 2],
  ['0xab3d9c7dd74db7dcb439b20a3dad1b517610cdee', 2],
  ['0xf1a834ef3fbb14b3adbea959396507aa3c88d2d1', 2],
  ['0xd34bad9d6fdd71a986e48d2f159e4d9199f4b10d', 2],
  ['0x2379d626804e2bc6f016761f12ec6d2881c6f682', 2],
  ['0xdfef7b28557689f1d0588bb735d1792ce625a83b', 2],
  ['0x5b9a9ae7fe9cd54cb4c0de11a88db698798482f1', 2],
  ['0xbff49f00f46855b9b5fc6d684186089dec1c36c0', 2],
  ['0xfe0e1f1917999e38be696ddcc0215a1dbe056932', 2],
  ['0x72d5119e02fa3cb907922c8ea3de35f314bbd38c', 2],
  ['0xf406aed3369614123eb6c8ea6afb12995d49dbdb', 2],
  ['0xcb5604ec27f94640dfbce2d48556b0397bef8b71', 2],
  ['0x254ebef3fc9e42f7102c2ca2b275c0d67ba20113', 2],
  ['0x7056f0e43ad1454d2da9b9c33fc8716f55b25477', 2],
  ['0x46e119092c3bdd31e7534dbfd97df2c26710496a', 2],
  ['0x8919127468ea024d575e0e19af23ad009a435466', 2],
  ['0x562be1e2c8fe614cfef13f6679d0516051f77bac', 2],
  ['0xcd2969193cfcec1eff07e717f6c1f7f9afa94021', 2],
  ['0xb618af743fe37ec708b0015736ee4c7a6583646d', 2],
  ['0xc0166b8c57a976c429ea00b2b7a50d484b46cdeb', 2],
  ['0x0a06af9407c9931a7c9e4bc808d5a05adc7087e9', 2],
  ['0xaf420837aed23644c6a83fd41c071bd2d15b6478', 2],
  ['0x6de74cb123ef9546cd610546641399c183175c31', 2],
  ['0x5c13296fc2fa3bd5916425808c0e7b846ecdad12', 2],
  ['0xd7db6d54a1ab16108946f1b6fc13ce352fd3f01b', 2],
  ['0xf287eb78152c169195730f92900b8cb10962ab9f', 2],
  ['0x8d57f7b6becd518b681df5c8701969ff5a8598a5', 2],
  ['0x83f38a83575a825da7f4d6ea8be171b3ded79e37', 2],
  ['0x697feec3ad6eadd6c2444507d81b56d787cbc637', 2],
  ['0x0eb8e5e8723f4ba8ce19025fcda41d2e330cfdc5', 2],
  ['0x9cdf4aa3c6ce02b83a0f77b7cceaa62fc004913c', 2],
  ['0x9dc8647918c973cb6fde0a0dea950c793d895224', 2],
  ['0x9ca5c98f650caecca2d0d5d3e62f2667ad55bef3', 2],
  ['0xbd65b3ea603b9bd0f07532564ec2ccda044997d7', 2],
  ['0x71564f600c4e29f33fe0fe73d6a66fae68a4337e', 2],
  ['0x121716a860c745c73dc3d8fe7eb43389751cdc3c', 2],
  ['0x1b6d6bbb64f183a8734f199ee1168189beefa354', 2],
  ['0xa745bef759f71037dadd3f41ce6d87467217fc72', 2],
  ['0x9a392054159680f4093940defb33b81ccfedbfb5', 2],
  ['0x584f3584ce43d3d53a97b93277a38440e6cfaa5a', 2],
  ['0xbeb8c6e1697984bcbe20d871ed7610bdeb678e6a', 2],
  ['0xeb4c1de511be35dcc783fb44162ba9e8497652fb', 2],
  ['0xe096a15947df68b1a9993005e76d2bf77e917fdf', 2],
  ['0x329d06e10910e87724d6b84aa2108e947ecc2166', 2],
  ['0x335475d760078dcdf4d23619fd0e4c467729d5bd', 2],
  ['0x0affbf300d863a6d169b1f3254e5aa42bbff60c4', 2],
  ['0x7e9c268d38da976a1b371ec72cdc83c728177858', 2],
  ['0xa2df2e3771c68ff5dc17c9abcc19953ae7ef753c', 2],
  ['0x2fa03dcc825f2a09705904bc8f6e51662e9c9448', 2],
  ['0x8b392446bf66de5c82ed7b85d04e410da606877d', 2],
  ['0xbe6a7f5044dd36e565c8b8be891abc8a9470358e', 2],
  ['0x40e929dfa7c4064664db1da679027d3ae5f84792', 2],
  ['0x68185e4a36896e673d2cf9120a5890fd4c9330cd', 2],
  ['0x592234c63ac3c816b0485761bc00fc1b932d18fd', 2],
  ['0x16bcf22c9440d44f1ed394c6597a698b599bfa03', 2],
  ['0x25d3beaa4963f7d7485765982474b1eaf96e0ac5', 2],
  ['0xf25bcdb37c23298f56f347e09fb4117e5de6b123', 2],
  ['0x0e34256ce6d85d04ff192e4bf9c40994ad901e30', 2],
  ['0x4fccd9576e83b347be9ce38264d3caa9ebc26218', 2],
  ['0x08229e2fbed1441773dcfe3093cb7d7d8b0d08c8', 2],
  ['0x97c19f6faffec1f1704def5a01c5f571608406de', 2],
  ['0x0704871b731f093ba625afaa41144ad06d86bcd5', 2],
  ['0xb566dd548ad0b6a9d27e71e0faa19e4063e4bb5d', 2],
  ['0xf533c31ff773589fdc4dfa562990459d711f116e', 2],
  ['0x9162d45686b1e73050d656b48d7d21aa1ef90fe5', 2],
  ['0x6f4e9b653a80ef2c08114c2cd9d0a16c41a7065b', 2],
  ['0x780f60bbd746003fbbe755829d2f54f773453792', 2],
  ['0x5de280a131d480d328ae26fa5a7c423684825595', 2],
  ['0xd0dbf57588e29ff06c4c5a23e4d699d2af6e612b', 2],
  ['0x0eb72490a6000fc9ef015c739a40f1bbd238aa64', 2],
  ['0xf76c8182c97e2ec5417de0dfd7d86864ef03703a', 2],
  ['0x80a9f73bbb7d31ced04a76cad4a90753e8929102', 2],
  ['0xbcefeb5f4ca0b5095625f9f1eba771bd74845c50', 2],
  ['0x17e3c688b6cbf680fb63f175cf35d79c15825875', 2],
  ['0x13d07ab3bd1b404bfefca8835a9a702022c3dbde', 2],
  ['0xabe2366a9de4d64e57d89c819907c3902f2a51a4', 2],
  ['0xd00045a4c964046296736c31378bcfdc1642e5e6', 2],
  ['0x68aa32e2ebd14e37d47ec4ed7147e559d683c87b', 2],
  ['0xd165781a7cce95e89a18a22803ba5f2517b19985', 2],
  ['0xcadca10bd6c65c26c7dfd6faa874980fcc5a7831', 2],
  ['0x520ac9be64698ca63f2c15a49b6b5d097d08eda7', 2],
  ['0x410409f9a435a5cee7699aa34b0bad799d89b562', 2],
  ['0x4acd24be9c4f554b6989bd00c3904ccfa97c1b0b', 2],
  ['0xfa3780738ab4c10c9c93a777a3c12b650df42de8', 2],
  ['0x9dbe47ce17b6deaac7182dc78f5b23fa1776ba18', 2],
  ['0xbee5edf48c2452a6236a5ea4d6d0be3bad41583d', 2],
  ['0xf341240710d2007f2c33b36ae69156b1c0c8af08', 2],
  ['0x5d24f0a214e14d79ca728a776d2f03fd7a35db66', 2],
  ['0x17777182130e56c2d1250be00bb0c83e6035f63c', 2],
  ['0x623323ef39f5f5d05545c8109dad7175c69553c2', 2],
  ['0x8d3f8209338e0df391bca4e602d66766f3e54f3b', 2],
  ['0xb826845844459d790bdb17b12a4c3f891689dce5', 2],
  ['0x212ead9e6c9bb77069bf79f6bdbc8dad66656856', 2],
  ['0x1b2137ebe368f49d31a84998794409286f63b693', 2],
  ['0xff30b32c7e7da16cc7cd100a54ecd77b103d1a1c', 2],
];
